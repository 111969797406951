// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dJ_FG";
export var careerWorking__btnWrapper = "dJ_FF";
export var careerWorking__card = "dJ_Ft";
export var careerWorking__cardBgDark = "dJ_Fw";
export var careerWorking__cardBgLight = "dJ_Fv";
export var careerWorking__cardDescription = "dJ_FC";
export var careerWorking__cardHorizontal = "dJ_Fx";
export var careerWorking__cardHorizontalBox = "dJ_Fy";
export var careerWorking__cardHorizontalImage = "dJ_Fz";
export var careerWorking__cardImage = "dJ_FD";
export var careerWorking__cardTitle = "dJ_FB";